import axios from '@/utils/axios'

// 发送扫码
export const payBox = data =>
  axios.post('/shop/pay/payBox', data)
    .then(res => res)

// 请求支付链接
export const getPayUrl = data =>
  axios.post('/shop/pay/getPayUrl', data)
    .then(res => res)